// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-js": () => import("./../../../src/components/Post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hubungi-kami-js": () => import("./../../../src/pages/hubungi-kami.js" /* webpackChunkName: "component---src-pages-hubungi-kami-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kebijakan-privasi-js": () => import("./../../../src/pages/kebijakan-privasi.js" /* webpackChunkName: "component---src-pages-kebijakan-privasi-js" */),
  "component---src-pages-tentang-kami-js": () => import("./../../../src/pages/tentang-kami.js" /* webpackChunkName: "component---src-pages-tentang-kami-js" */),
  "component---src-pages-tips-judi-online-js": () => import("./../../../src/pages/tips-judi-online.js" /* webpackChunkName: "component---src-pages-tips-judi-online-js" */)
}

